"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _data = require("./data");
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)('auth'),
  mapState = _createNamespacedHelp.mapState,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  data: function data() {
    return {
      TipsType: _data.TipsType,
      type: ''
    };
  },
  watch: {
    $route: {
      handler: function handler() {
        this.type = this.$route.query.type;
      },
      immediate: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    isCheckMediaLoading: function isCheckMediaLoading(state) {
      return state.isCheckMediaLoading;
    },
    isGetAuthUrlLoading: function isGetAuthUrlLoading(state) {
      return state.isGetAuthUrlLoading;
    },
    checkMediaList: function checkMediaList(state) {
      return state.checkMediaList;
    }
  })), {}, {
    imgSrc: function imgSrc() {
      return require("@/assets/tips/".concat(this.type, ".png"));
    },
    tipsText: function tipsText() {
      return _data.TipsText[this.type];
    },
    tipsButtonText: function tipsButtonText() {
      return _data.TipsButtonText[this.type];
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(['setState', 'checkMediaSetting', 'getAuthUrl'])), {}, {
    handleButtonClick: function handleButtonClick() {
      switch (this.type) {
        case _data.TipsType.NOT_SETTING:
          this.checkMediaSetting();
          break;
        case _data.TipsType.NOT_ADD:
          this.setState({
            label: 'entryGuideVisible',
            value: true
          });
          break;
        case _data.TipsType.CHECKING:
          this.setState({
            label: 'entryGuideVisible',
            value: true
          });
          break;
        case _data.TipsType.NOT_AUTH:
          this.getAuthUrl(true);
          break;
        case _data.TipsType.NOT_ENOUGH_AUTH:
          this.getAuthUrl(true);
          break;
      }
    }
  }),
  created: function created() {
    this.type = this.$route.query.type;
  }
};
exports.default = _default;