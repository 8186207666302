"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _home = _interopRequireDefault(require("@/api/home"));
var _TalkRecord = _interopRequireDefault(require("./TalkRecord.vue"));
var _common = _interopRequireDefault(require("@/utils/common"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var columns_leave = [{
  title: '电话/微信',
  scopedSlots: {
    customRender: 'contact'
  },
  width: 140,
  fixed: 'left'
}, {
  title: '抖音昵称',
  dataIndex: 'user_nicknames',
  scopedSlots: {
    customRender: 'user_nicknames'
  },
  width: 120,
  fixed: 'left'
}, {
  title: '留资时间',
  dataIndex: 'leave_time',
  width: 200
}, {
  title: '等待联系时长',
  scopedSlots: {
    customRender: 'wait_contact_time'
  },
  width: 120
}, {
  title: '跟进状态',
  scopedSlots: {
    customRender: 'followUpState'
  },
  width: 120
}, {
  title: '备注',
  scopedSlots: {
    customRender: 'remarks'
  },
  width: 120
}, {
  title: '用户所在城市',
  dataIndex: 'location_city',
  scopedSlots: {
    customRender: 'location_city'
  },
  width: 100
}, {
  title: '私信提及城市',
  dataIndex: 'mention_city',
  scopedSlots: {
    customRender: 'mention_city'
  },
  width: 100
}, {
  title: '意向车型',
  dataIndex: 'intention_series',
  scopedSlots: {
    customRender: 'intention_series'
  },
  width: 120
}, {
  title: '价值互动',
  scopedSlots: {
    customRender: 'interactionList'
  },
  width: 200
}, {
  title: '命中策略',
  scopedSlots: {
    customRender: 'hitStrategyList'
  },
  width: 120
}, {
  title: '留资来源',
  dataIndex: 'leave_type',
  scopedSlots: {
    customRender: 'leave_type'
  },
  width: 100
}, {
  title: '留资归属',
  dataIndex: 'scene_type_names',
  width: 100
}, {
  title: '操作',
  scopedSlots: {
    customRender: 'action'
  },
  width: 120,
  fixed: 'right'
}];
var leaveTypeMap = {
  0: '其他',
  1: '短视频',
  2: '直播',
  3: '企业主页',
  4: '私信',
  5: '订阅文章'
};
var _default = {
  props: {
    listData: {
      type: Array,
      default: []
    },
    showPhone: {
      type: Boolean,
      default: false
    },
    paginationLeave: {
      type: Object,
      default: {}
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var validRemark = function validRemark(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入备注！'));
      } else if (value.length > 100) {
        callback(new Error('最多100个字！'));
      } else {
        callback();
      }
    };
    return {
      columns: columns_leave,
      leaveTypeMap: leaveTypeMap,
      pagination: {
        pageSize: 10,
        total: 0,
        showTotal: function showTotal(total) {
          return "\u5171".concat(total, "\u6761\u8BB0\u5F55");
        },
        hideOnSinglePage: true
      },
      userList: [],
      currentUserIndex: 0,
      historyListLoading: false,
      history_chat_list: [],
      currentAuthorData: {},
      visible: false,
      othorPagination: false,
      userOpenId: '',
      followUpStateList: [{
        id: 1,
        label: '未跟进'
      }, {
        id: 2,
        label: '跟进中'
      }, {
        id: 3,
        label: '已跟进'
      }],
      remarks_visible: false,
      form: {
        remark: ''
      },
      rules: {
        remarks: [{
          required: true,
          validator: validRemark,
          trigger: 'blur'
        }]
      }
    };
  },
  components: {
    TalkRecord: _TalkRecord.default
  },
  methods: {
    formatDuration: _common.default.formatDuration,
    formatList: function formatList(list) {
      return list ? list.split(',') : [];
    },
    disposePercent: function disposePercent(text, value) {
      var percent = null;
      if (text > 0) {
        percent = (text / value * 100).toFixed(2) + '%';
      } else {
        percent = 0 + '%';
      }
      return percent;
    },
    phoneNuberConvert: function phoneNuberConvert(number) {
      if (!number) return '';
      var pat = /(\d{3})\d*(\d{4})/;
      var result = number.replace(pat, '$1***$2');
      return result;
    },
    changeUser: function changeUser(index) {
      var _this = this;
      this.currentUserIndex = index;
      this.userOpenId = this.userList[index].user_open_id;
      this.historyListLoading = true;
      var payload = {
        userOpenId: this.userOpenId,
        params: {
          client: 1
        }
      };
      _home.default.getLiveLeaveDetail(payload).then(function (res) {
        if (res.code == 200) {
          _this.history_chat_list = res.data;
          _this.$nextTick(function () {
            _this.$refs['chatBox'].scrollTop = 0;
          });
        }
        console.log(res);
      }).finally(function () {
        _this.historyListLoading = false;
      });
      this.filtrate.setTracking({
        en: 'zxmk_yx_lzmxyxhs_click'
      });
      console.log('index:', index);
    },
    openDrawer: function openDrawer(value) {
      this.userList = value.user_list;
      this.history_chat_list = [];
      this.visible = true;
      this.changeUser(0);
    },
    onClose: function onClose() {
      this.visible = false;
    },
    settingCity: function settingCity() {
      var _this2 = this;
      // this.visible_modal = true;
      this.$confirm('可以在【配置目标城市】页面详细了解!', '目标城市', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.$router.push({
          path: '/setting/setting_city'
        });
        _this2.filtrate.setTracking({
          en: 'zxmk_yx_gzzdsxtjmbcsck_click'
        });
      }).catch(function () {
        console.log('cancel');
      });
    },
    gotoChat: function gotoChat() {
      this.$router.push({
        path: '/private_letter_manage/conversation',
        query: {
          userOpenId: this.userOpenId
        }
      });
    },
    /**
     * 编辑备注
     * @param {*} row
     * @return {*}
     */
    editRemarks: function editRemarks(row) {
      this.remarks_visible = true;
      this.form = {
        clue_follow_up_id: row.id,
        remarks: row.remarks
      };
    },
    handleOk: function handleOk() {
      var _this3 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          console.log('备注');
          _this3.updateFollowUpStateAndRemarks(_this3.form);
        } else {
          return false;
        }
      });
    },
    changeEdit: function changeEdit(row) {
      console.log('row:', row);
      this.updateFollowUpStateAndRemarks({
        clue_follow_up_id: row.id,
        follow_up_status: row.follow_up_state
      });
    },
    updateFollowUpStateAndRemarks: function updateFollowUpStateAndRemarks(params) {
      var _this4 = this;
      _home.default.updateFollowUpStateAndRemarks(params).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success('保存成功');
          _this4.$emit('changeTable');
          _this4.handleCancel();
        } else {
          _this4.$message.error(res.message);
        }
      });
    },
    handleCancel: function handleCancel() {
      this.remarks_visible = false;
      this.form.remarks = '';
      this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
    }
  }
};
exports.default = _default;