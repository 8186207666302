"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    dataList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    dataList: {
      handler: function handler(val) {
        this.list = val;
        this.isAdd = false;
      },
      immediate: true,
      deep: true
    }
  },
  data: function data() {
    return {
      list: [],
      isAdd: false
    };
  },
  methods: {
    //编辑
    editTactics: function editTactics(item) {
      var _this = this;
      this.$set(item, 'isEdit', true);
      this.$set(item, 'value', item.replyMsg);
      this.$nextTick(function () {
        _this.$refs["textarea_".concat(item.id)][0].focus();
      });
    },
    //删除
    deleteTactics: function deleteTactics(item) {
      var _this2 = this;
      var confirmText = ['删除后话术将无法恢复', '请确认是否删除？'];
      var content = [];
      var h = this.$createElement;
      for (var i in confirmText) {
        content.push(h("p", null, confirmText[i]));
      }
      this.$confirm('', {
        title: '温馨提示',
        message: h('div', null, content),
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this2.$set(item, 'isSubmit', true);
        _this2.$emit('delete', item);
      }).catch(function () {});
    },
    //新增
    addTactics: function addTactics() {
      var _this3 = this;
      this.isAdd = true;
      this.list.push({
        id: '0-0',
        replyMsg: '',
        isEdit: true,
        isAdd: true
      });
      this.$nextTick(function () {
        _this3.$refs["textarea_0-0"][0].focus();
        _this3.$refs.listWrap.scrollLeft = _this3.$refs.listWrap.scrollWidth;
      });
    },
    //取消
    close: function close(item, index) {
      if (item.isAdd) {
        this.isAdd = false;
        this.list.splice(index, 1);
      } else {
        item.isEdit = false;
        item.replyMsg = item.value;
      }
    },
    //保存
    submit: function submit(item) {
      if (item.replyMsg) {
        this.isAdd = false;
        this.$set(item, 'isSubmit', true);
        this.$emit('submit', item);
      } else {
        this.$message.error('请输入回复内容');
      }
    },
    scrollLeft: function scrollLeft() {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.$refs.listWrap.scrollLeft = _this4.$refs.listWrap.scrollWidth;
      });
    }
  }
};
exports.default = _default2;