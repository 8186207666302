var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "top" }, [
        _c(
          "div",
          { staticClass: "btn-box" },
          [
            _c(
              "a-radio-group",
              {
                model: {
                  value: _vm.activeType,
                  callback: function ($$v) {
                    _vm.activeType = $$v
                  },
                  expression: "activeType",
                },
              },
              [
                _c("a-radio-button", { attrs: { value: 1 } }, [
                  _vm._v("关键词识别"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "a-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.showAddDrawer },
                  },
                  [_vm._v("新增关键词组")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "note" }, [
          _vm._v(
            "私信内容命中任意关键词时，自动发送设置好的话术；命中多个关键词组时，只触发优先级最高的"
          ),
        ]),
      ]),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _vm.dataList && _vm.dataList.length > 0
            ? _vm._l(_vm.dataList, function (item, index) {
                return _c(
                  "div",
                  { key: item.strategyId, staticClass: "box" },
                  [
                    !item.editTitle
                      ? _c("div", { staticClass: "title" }, [
                          _c("span", [_vm._v(_vm._s(item.keyWordGroup))]),
                          _c(
                            "span",
                            {
                              staticClass: "edit",
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item, "name", "name")
                                },
                              },
                            },
                            [_c("a-icon", { attrs: { type: "edit" } })],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "delete" },
                            [
                              !item.isSubmit
                                ? _c("a-icon", {
                                    attrs: { type: "delete" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteGroup(item)
                                      },
                                    },
                                  })
                                : _c("a-icon", { attrs: { type: "loading" } }),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "title" }, [
                          _c(
                            "div",
                            { staticClass: "input-box" },
                            [
                              _c("a-input", {
                                ref: "name_" + item.strategyId,
                                refInFor: true,
                                staticClass: "input",
                                attrs: {
                                  placeholder: "请输入关键词组名",
                                  maxLength: 20,
                                },
                                model: {
                                  value: item.keyWordGroup,
                                  callback: function ($$v) {
                                    _vm.$set(item, "keyWordGroup", $$v)
                                  },
                                  expression: "item.keyWordGroup",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "bottom" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "btn",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.close(item, "name", "name")
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "btn",
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled: item.isSubmit,
                                        loading: item.isSubmit,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.save(item, "name", "name")
                                        },
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                    item.detailsId
                      ? _c("div", { staticClass: "keyword" }, [
                          _c("div", { staticClass: "key" }, [
                            _vm._v("关键词:"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "input-box" },
                            [
                              !item.isEdit
                                ? _c(
                                    "div",
                                    { staticStyle: { padding: "4px 11px" } },
                                    [_vm._v(_vm._s(item.detailsName) + " ")]
                                  )
                                : _c("a-textarea", {
                                    ref: "keyword_" + item.strategyId,
                                    refInFor: true,
                                    staticClass: "input",
                                    attrs: {
                                      placeholder: "请输入关键词",
                                      "auto-size": { minRows: 2, maxRows: 4 },
                                      maxLength: 200,
                                      disabled: !item.isEdit,
                                    },
                                    model: {
                                      value: item.detailsName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "detailsName", $$v)
                                      },
                                      expression: "item.detailsName",
                                    },
                                  }),
                              _c(
                                "div",
                                { staticClass: "bottom" },
                                [
                                  !item.isEdit
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "btn edit",
                                            on: {
                                              click: function ($event) {
                                                return _vm.edit(item, "keyword")
                                              },
                                            },
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "edit" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "btn",
                                            attrs: { size: "small" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.close(
                                                  item,
                                                  "keyword"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                              disabled: item.isSubmit,
                                              loading: item.isSubmit,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.save(item, "keyword")
                                              },
                                            },
                                          },
                                          [_vm._v("保存")]
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("textareaContent", {
                      ref: "textareaContent_" + item.strategyId,
                      refInFor: true,
                      attrs: { "data-list": item.replyMsgVos || [] },
                      on: {
                        delete: function ($event) {
                          return _vm.onDelete(arguments, item)
                        },
                        submit: function ($event) {
                          return _vm.onSubmit(arguments, item)
                        },
                      },
                    }),
                  ],
                  1
                )
              })
            : _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")]),
        ],
        2
      ),
      _c(
        "FormDrawer",
        {
          attrs: {
            title: "新增关键词组",
            width: "620",
            visible: _vm.addVisible,
            loading: _vm.addSaving,
            maskClosable: false,
            submitText: "保存",
          },
          on: {
            close: _vm.closeAddDrawer,
            submit: function ($event) {
              return _vm.saveAddData()
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "关键词组名",
                    colon: false,
                    prop: "group_name",
                  },
                },
                [
                  _c("a-input", {
                    staticClass: "input",
                    attrs: { placeholder: "最多20个字", maxLength: 20 },
                    model: {
                      value: _vm.form.group_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "group_name", $$v)
                      },
                      expression: "form.group_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "关键词",
                    colon: false,
                    prop: "details_name",
                  },
                },
                [
                  _c("a-textarea", {
                    staticClass: "input",
                    attrs: {
                      placeholder: '最多200个字，多个关键词用英文逗号","间隔',
                      "auto-size": { minRows: 3, maxRows: 5 },
                      maxLength: 200,
                    },
                    model: {
                      value: _vm.form.details_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "details_name", $$v)
                      },
                      expression: "form.details_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SortDrawer", { ref: "SortDrawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }