"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    chartData: {
      type: Array,
      default: []
    },
    fansCount: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: function chartData(val) {
      this.initChart();
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose(); // 销毁实例
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById("liveBar"));
      var that = this;
      this.chart.setOption({
        // color: ["#6395f9"],
        // title: {
        //   text: "World Population",
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: "20px",
          left: "20px",
          right: "12%",
          bottom: "20px",
          containLabel: true
        },
        xAxis: {
          type: "value",
          position: "top",
          boundaryGap: [0, 0.01],
          // axisTick: {
          //   show: false,
          // },
          splitLine: {
            show: false
          },
          // axisLine: {
          //   show: false,
          // },
          z: 10
        },
        yAxis: {
          // axisLine: {
          //   show: false,
          // },
          axisTick: {
            show: false
          },
          type: "category",
          data: ["策略有效留资人数", "策略有效回复人数", "策略-主动私信人数", "命中策略人数", "价值潜客人数"]
        },
        series: [{
          type: "bar",
          itemStyle: {
            emphasis: {
              barBorderRadius: 7
            },
            normal: {
              barBorderRadius: 7,
              color: new _echarts.default.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: "#3977E6"
              }, {
                offset: 1,
                color: "#37BBF8"
              }]),
              label: {
                show: true,
                position: "right",
                textStyle: {
                  color: "#666"
                },
                formatter: function formatter(val) {
                  var str = "0%";
                  if (val.value != 0) {
                    str = (val.value / that.fansCount * 100).toFixed(2) + "%";
                  }
                  return str + "," + val.value;
                }
              }
            }
          },
          data: that.chartData
        }]
      });
    }
  }
};
exports.default = _default;