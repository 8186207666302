"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    chartData: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: function chartData(val) {
      this.initChart();
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose(); // 销毁实例
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById("livePie"));
      var that = this;
      this.chart.setOption({
        color: ["#fc8452", "#3977E6"],
        title: {
          text: "留资占比",
          left: "center",
          color: "#999"
        },
        tooltip: {
          trigger: "item"
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left",
        //   show:false
        // },
        series: [{
          name: "占比",
          type: "pie",
          radius: "60%",
          data: this.chartData,
          itemStyle: {
            normal: {
              borderWidth: 2,
              borderColor: "#fff",
              label: {
                color: "#fff",
                fontSize: 12,
                position: "inner",
                formatter: "{b} \n{c} ({d}%)"
              }
            }
          }
          // emphasis: {
          //   itemStyle: {
          //     shadowBlur: 10,
          //     shadowOffsetX: 0,
          //     shadowColor: "rgba(0, 0, 0, 0.5)",
          //   },
          // },
        }]
      });
    }
  }
};
exports.default = _default;