var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.mediaSettingVisible,
            closable: false,
            maskClosable: false,
            title: "设置客情主营媒体号",
          },
        },
        [
          _c(
            "div",
            { staticClass: "media-list" },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "100%" },
                  attrs: { value: _vm.bindMedia },
                  on: { change: _vm.handleMediaChange },
                },
                _vm._l(_vm.bindMediaList, function (item, index) {
                  return _c(
                    "label",
                    {
                      key: index,
                      staticClass: "media-info",
                      attrs: { for: "media" + index },
                    },
                    [
                      _c("label", { attrs: { for: "" } }),
                      _c("div", { staticClass: "img" }, [
                        _c("img", { attrs: { src: item.avatar, alt: "" } }),
                        _c("img", {
                          staticClass: "dy-logo",
                          attrs: {
                            src: require("@/assets/icon/dy.png"),
                            alt: "",
                            srcset: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "name-info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.nickname)),
                        ]),
                        _c("span", [_vm._v(_vm._s(item.code))]),
                      ]),
                      _c("a-radio", {
                        key: "media" + index,
                        attrs: { id: "media" + index, value: item.authorId },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("a-icon", { attrs: { type: "exclamation-circle" } }),
              _vm._v(" 仅限抖音蓝V号和员工号，"),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.setState({
                        label: "entryGuideVisible",
                        value: true,
                      })
                    },
                  },
                },
                [_vm._v("查看添加入口")]
              ),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "mediaSettingVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "primary" },
                    on: { click: _vm.setMedia },
                  },
                  [_vm._v(" 确定 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.addMediaVisible,
            closable: false,
            maskClosable: false,
            title: "设置客情主营媒体号",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("检测到未添加抖音媒体号，请在风火轮APP中添加"),
          ]),
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("a-icon", { attrs: { type: "exclamation-circle" } }),
              _vm._v(" 仅限抖音蓝V号和员工号 "),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "entryGuideVisible",
                          value: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" 查看添加入口 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "addMediaVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 我知道了 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.checkingVisible,
            closable: false,
            maskClosable: false,
            title: "温馨提示",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v(" 添加的抖音号"),
            _c("span", { staticClass: "media-name" }, [
              _vm._v(_vm._s(_vm.checkMediaList.join("、"))),
            ]),
            _vm._v("正在审核中，请耐心等待.. "),
          ]),
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("a-icon", { attrs: { type: "exclamation-circle" } }),
              _vm._v(" 仅限抖音蓝V号和员工号 "),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "checkingVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 我知道了 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.authChangeTipsVisible,
            closable: false,
            maskClosable: false,
            title: "温馨提示",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("检测到抖音授权项更新，请重新授权"),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "authChangeTipsVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "primary",
                      loading: _vm.isGetAuthUrlLoading,
                    },
                    on: { click: _vm.getAuthUrl },
                  },
                  [_vm._v(" 去授权 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.authTipsVisible,
            closable: false,
            maskClosable: false,
            title: "温馨提示",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("风火轮将对您门店的抖音号私信、视频评论等信息进行管理"),
          ]),
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("请先完成抖音号的授权"),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "default",
                      loading: _vm.isGetAuthUrlLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "authTipsVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "primary",
                      loading: _vm.isGetAuthUrlLoading,
                    },
                    on: { click: _vm.getAuthUrl },
                  },
                  [_vm._v(" 去授权 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            width: 540,
            visible: _vm.entryGuideVisible,
            maskClosable: false,
            footer: null,
            title: "添加入口",
          },
          on: {
            cancel: function ($event) {
              return _vm.setState({ label: "entryGuideVisible", value: false })
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { width: "300px" },
              attrs: { src: require("@/assets/dev/entry-guid.png"), alt: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }