var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "tips-box" }, [
        _c(
          "div",
          { staticClass: "title-header" },
          [
            _vm._v(" 套路对话 "),
            _c("a-switch", {
              attrs: { loading: _vm.isOpening },
              on: { click: _vm.switchCheck },
              model: {
                value: _vm.isOpened,
                callback: function ($$v) {
                  _vm.isOpened = $$v
                },
                expression: "isOpened",
              },
            }),
          ],
          1
        ),
        _c("p", [
          _vm._v(
            "1、开启后功能将按预设的对话流程，通过一系列引导性问题和回复，逐步获取用户的联系方式"
          ),
        ]),
        _c("p", [
          _vm._v(
            "2、每个策略最多支持设置10条话术，回复时从已设置的话术中随机选择1条"
          ),
        ]),
        _c("div", { staticClass: "setting-box" }, [
          _c(
            "div",
            [
              _c(
                "a-checkbox",
                {
                  on: { change: _vm.onChangeCheckbox },
                  model: {
                    value: _vm.isAuto,
                    callback: function ($$v) {
                      _vm.isAuto = $$v
                    },
                    expression: "isAuto",
                  },
                },
                [_vm._v("自动更新套路配置")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "txt" }, [
            _vm._v(
              "选中后，系统定期更新配置时将同步到您的账号，但不会覆盖您自定义的内容"
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-content" },
        [
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.activeType,
                callback: function ($$v) {
                  _vm.activeType = $$v
                },
                expression: "activeType",
              },
            },
            [
              _c("a-tab-pane", { key: 1, attrs: { tab: "基础对话配置" } }),
              _c("a-tab-pane", { key: 2, attrs: { tab: "自定义回复配置" } }),
            ],
            1
          ),
          _c("BasicConfiguration", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeType == 1,
                expression: "activeType == 1",
              },
            ],
            ref: "BasicConfiguration",
          }),
          _c("CustomizedConfiguration", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeType == 2,
                expression: "activeType == 2",
              },
            ],
            ref: "CustomizedConfiguration",
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "开启套路对话功能" },
          on: { cancel: _vm.handleCancel },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isSubmit,
                    loading: _vm.isSubmit,
                  },
                  on: { click: _vm.handleOk },
                },
                [_vm._v("开启")]
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(
              "开启「套路对话」功能，将用「套路对话」代替私信接待、私信智回、私信潜客召回功能承接私信回复"
            ),
          ]),
          _c("div", [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    model: {
                      value: _vm.isAutoModal,
                      callback: function ($$v) {
                        _vm.isAutoModal = $$v
                      },
                      expression: "isAutoModal",
                    },
                  },
                  [_vm._v("自动更新套路配置")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "txt" }, [
              _vm._v(
                "选中后，系统定期更新配置时将同步到您的账号，但不会覆盖您自定义的内容"
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("OpenCheckModal", { ref: "OpenCheckModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }