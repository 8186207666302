"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _home = _interopRequireDefault(require("@/api/home.js"));
var _attention = _interopRequireDefault(require("@/api/attention.js"));
var _Fptable = _interopRequireDefault(require("./components/Fptable.vue"));
var _learn_one = _interopRequireDefault(require("../../assets/live_images/learn_one.png"));
var _learn_two = _interopRequireDefault(require("../../assets/live_images/learn_two.png"));
var _common = _interopRequireDefault(require("@/api/common"));
var _LiveAnalys = _interopRequireDefault(require("@/components/Charts/BarChart/LiveAnalys.vue"));
var _liveAnalysPie = _interopRequireDefault(require("@/components/Charts/Pie/liveAnalysPie.vue"));
var _alertMarning = _interopRequireDefault(require("@/components/alertMarning"));
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      timeBtn: 2,
      btn: [{
        value: 1,
        name: '昨天'
      }, {
        value: 2,
        name: '近7天'
      }, {
        value: 3,
        name: '近30天'
      }],
      time: [],
      attentionType: 'getLiveEffect',
      open_fans_info: true,
      incrIntercat: {
        // empower_fans_info: 0,
        open_fans_info: 0
      },
      previewVisible: false,
      previewImage: '',
      btn_loading: false,
      active_btn: undefined,
      endDay: '',
      startDay: '',
      listData: [],
      chartData: [],
      pieData: [],
      showPhone: false,
      fansCount: 0,
      pagination: {},
      tableLoading: false,
      show_setting: false,
      loginVisible: false,
      setVisible: false,
      authorizationVisible: false,
      authorizationStatusData: false,
      infoData: {},
      num: 2,
      isGetAuthUrl: false
    };
  },
  components: {
    Fptable: _Fptable.default,
    LiveAnalys: _LiveAnalys.default,
    LiveAnalysPie: _liveAnalysPie.default,
    alertMarning: _alertMarning.default
  },
  mounted: function mounted() {
    this.loginPcOrClickGuestSentiment();
    this.updataStatus(1, 0);
    this.changeDay(2);
    this.getTargetCity();
    this.getInitiativeMarketingAnnouncementInfo();
  },
  methods: {
    loginPcOrClickGuestSentiment: function loginPcOrClickGuestSentiment() {
      var _this = this;
      _attention.default.loginPcOrClickGuestSentiment({
        clientType: 1
      }).then(function (res) {
        console.log('首次登录是否弹窗', res);
        if (res.code == 200) {
          _this.authorizationStatusData = res.data || {};
          _this.authorizationStatusData.authorAvatar = _this.authorizationStatusData.authorAvatar ? _this.authorizationStatusData.authorAvatar.replace(/heic/, 'jpg') : '';
          if (res.data.popupWindow) {
            _this.loginVisible = true;
          } else {
            // this.getAuthorizationStatus(0);
          }
        }
      });
    },
    //获取首次登陆成功
    loginFirstConfirm: function loginFirstConfirm() {
      var _this2 = this;
      _attention.default.loginFirstConfirm({
        clientType: 1
      }).then(function (res) {
        console.log('确认媒体号信息', res);
        if (res.code == 200) {
          _this2.loginVisible = false;
        }
      });
    },
    //确认媒体号信息
    getIsSetCareCity: function getIsSetCareCity() {
      var _this3 = this;
      _attention.default.getIsSetCareCity().then(function (res) {
        console.log('是否设置特别关注城市', res);
        if (res.code == 200) {
          if (!res.data) {
            _this3.setVisible = true;
          } else {
            _this3.setVisible = false;
          }
        }
      });
    },
    //是否设置特别关注城市
    getAuthorizationStatus: function getAuthorizationStatus(type) {
      var _this4 = this;
      _attention.default.getAuthorizationStatus().then(function (res) {
        console.log('授权状态', res);
        if (res.code == 200) {
          if (res.data) {
            _this4.authorizationVisible = false;
            _this4.getIsSetCareCity();
          } else {
            _this4.authorizationVisible = true;
            if (type == 1) {
              _this4.$message.error('抖音号未授权，请先完成授权');
            }
          }
        } else {
          _this4.$message.error(res.message);
        }
      });
    },
    //获取授权状态
    changeDay: function changeDay(e) {
      var num = e;
      if (e.target) {
        num = e.target.value;
      }
      var start_t = '';
      this.num = num;
      var end_t = this.moment().subtract(0, 'days').format('YYYY-MM-DD');
      switch (num) {
        case 1:
          start_t = this.moment().subtract(1, 'days').format('YYYY-MM-DD');
          break;
        case 2:
          start_t = this.moment().subtract(7, 'days').format('YYYY-MM-DD');
          break;
        case 3:
          start_t = this.moment().subtract(30, 'days').format('YYYY-MM-DD');
          break;
        default:
          break;
      }
      this.time = [start_t, end_t];
      this.startDay = start_t;
      this.endDay = end_t;
      // this.$nextTick(() => {
      this.getLiveEffect();
      this.getLiveProperty();
      // });
      if (this.attentionType == 'getLeaveDetail') {
        this.getList(1);
      }
    },
    disabledDate: function disabledDate(current) {
      return current < this.moment().subtract(30, 'days') || current > this.moment().subtract(0, 'day')
      // current > this.moment().endOf("day")
      ;
    },
    handleCancel: function handleCancel() {
      this.previewVisible = false;
    },
    openImg: function openImg(type) {
      if (type == 1) {
        this.previewImage = _learn_one.default;
      } else {
        this.previewImage = _learn_two.default;
      }
      var str = type == 1 ? 'zxmk_yx_gzhfslbkjc_click' : 'zxmk_yx_yxgzcktrzlkjc_click';
      this.filtrate.setTracking({
        en: str
      });
      this.previewVisible = true;
    },
    goRouter: function goRouter() {
      this.filtrate.setTracking({
        en: 'zxmk_yx_pzclhfqpz_click'
      });
      this.$router.push({
        path: '/live_new_attention/strategy'
      });
      // if (this.incrIntercat.empower_fans_info != 1) {
      //   const h = this.$createElement;
      //   this.$msgbox({
      //     title: "温馨提示",
      //     message: h("p", null, [
      //       h(
      //         "p",
      //         { style: "padding-bottom:16px" },
      //         "主动私信营销功能可以针对有直播价值评论的关注用户自动发送私信。"
      //       ),
      //       h("div", "在使用之前，需要您的抖音账号设置： "),
      //       h(
      //         "div",
      //         { style: "color: #F59A23" },
      //         "关注和粉丝列表设置为公开可见"
      //       ),
      //     ]),
      //     confirmButtonText: "如何设置",
      //   })
      //     .then(() => {
      //       this.$router.push({
      //         path: "/live_new_attention/strategy",
      //       });
      //     })
      //     .catch(() => {
      //       console.log("cancel");
      //     });
      // } else {
      //   this.$router.push({
      //     path: "/live_new_attention/strategy",
      //   });
      // }
    },
    //日期选择框
    onChangeDate: function onChangeDate(dates) {
      var start_time = '';
      var end_time = '';
      if (dates.length) {
        start_time = this.moment(dates[0]._d).format('YYYY-MM-DD');
        end_time = this.moment(dates[1]._d).format('YYYY-MM-DD');
        this.startDay = start_time;
        this.endDay = end_time;
      } else {
        this.startDay = '';
        this.endDay = '';
      }
      this.time = [start_time, end_time];
      this.timeBtn = null;
      this.getLiveEffect();
      this.getLiveProperty();
      this.filtrate.setTracking({
        en: 'zxmk_yx_gzzdsxtjrqsx_click',
        kv: {
          startDate: start_time,
          endDate: end_time
        }
      });
      if (this.attentionType == 'getLeaveDetail') {
        this.getList(1);
      }
    },
    // 获取列表数据
    getList: function getList(value) {
      var _this5 = this;
      var payload = {
        startDay: this.startDay,
        endDay: this.endDay
      };
      if (this.attentionType == 'getLeaveDetail') {
        payload.leaveType = 2;
        payload.pageNum = value;
        payload.pageSize = 10;
      }
      this.tableLoading = true;
      _home.default[this.attentionType](payload).then(function (res) {
        // if (res.code == 200) {
        _this5.listData = res.list || [];
        if (_this5.attentionType == 'getLeaveDetail') {
          _this5.pagination = {
            current: res.page_num,
            pageSize: 10,
            total: res.total,
            showTotal: function showTotal(total) {
              return "\u5171".concat(total, "\u6761\u8BB0\u5F55");
            },
            hideOnSinglePage: true,
            onChange: function onChange(page, pageSize) {
              // this.pagination.current = page;
              _this5.changePagination(page);
            }
          };
          _this5.paginationTotal = res.total;
        }
        // } else {
        //   this.$message.error(res.message);
        // }
      }).finally(function () {
        _this5.tableLoading = false;
      });
    },
    // 获取转化明细
    getLiveEffect: function getLiveEffect() {
      var _this6 = this;
      var payload = {
        startDay: this.startDay,
        endDay: this.endDay
      };
      var sevenDayLeaveInfoCount = 0,
        sevenDayResponseCount = 0,
        activeMsgCount = 0,
        sceneCount = 0,
        interactCount = 0,
        fansCount = 0;
      _home.default.getLiveEffect(payload).then(function (res) {
        if (_this6.attentionType == 'getLiveEffect') {
          _this6.listData = res.list || [];
        }
        res.list.forEach(function (el) {
          sevenDayLeaveInfoCount += el.sevenDayLeaveInfoCount;
          sevenDayResponseCount += el.sevenDayResponseCount;
          activeMsgCount += el.activeMsgCount;
          sceneCount += el.sceneCount;
          interactCount += el.interactCount;
          fansCount += el.fansCount;
        });
        _this6.chartData = [sevenDayLeaveInfoCount, sevenDayResponseCount, activeMsgCount, sceneCount, interactCount];
        _this6.fansCount = interactCount;
      });
    },
    // 留资明细
    getLiveProperty: function getLiveProperty() {
      var _this7 = this;
      var payload = {
        startDay: this.startDay,
        endDay: this.endDay
      };
      var targetCityCount = 0,
        otherCityCount = 0;
      _home.default.getLiveProperty(payload).then(function (res) {
        if (_this7.attentionType == 'getLiveProperty') {
          _this7.listData = res.list || [];
        }
        res.list.forEach(function (el) {
          targetCityCount += el.targetCityCount;
          otherCityCount += el.otherCityCount;
        });
        _this7.pieData = [{
          value: targetCityCount,
          name: '目标城市'
        }, {
          value: otherCityCount,
          name: '异地'
        }];
      });
    },
    updataStatus: function updataStatus(value, type) {
      var _this8 = this;
      this.active_btn = value;
      this.btn_loading = true;
      var req = value == 0 ? 'getIncrInteract' : 'getLiveIncrInteract';
      _home.default[req]().then(function (res) {
        if (res.code == 200) {
          if (res.data) {
            _this8.incrIntercat = res.data;
          } else {
            _this8.incrIntercat.open_fans_info = 0;
          }
          if (type != 0) {
            _this8.$message.success('状态更新成功');
          }
        } else {
          _this8.$message.error(res.message);
        }
      }).finally(function () {
        _this8.btn_loading = false;
      });
      var str = value == 1 ? 'zxmk_yx_gzhfslbgxzt_click' : 'zxmk_yx_yxgzcktrzlgxzt_click';
      this.filtrate.setTracking({
        en: str
      });
    },
    changeList: function changeList() {
      this.$refs.fptable.changeTable(this.attentionType);
      var str = '';
      switch (this.attentionType) {
        case 'getLiveEffect':
          str = 'zxmk_yx_gzzdsxtjzhmx_click';
          break;
        case 'getLiveProperty':
          str = 'zxmk_yx_gzzdsxtjlzmx_click';
          break;
        case 'getLeaveDetail':
          str = 'zxmk_yx_sylzmx_click';
          break;
        default:
          break;
      }
      this.getList(1);
      this.filtrate.setTracking({
        en: str
      });
    },
    showPhoneType: function showPhoneType() {
      this.filtrate.setTracking({
        en: 'zxmk_yx_lzmxzslxfs_click',
        kv: {
          state: this.attentionType ? '开启' : '关闭'
        }
      });
    },
    changePagination: function changePagination(value) {
      this.getList(value);
    },
    settingCity: function settingCity() {
      var _this9 = this;
      this.filtrate.setTracking({
        en: 'zxmk_yx_gzzdsxtjpzmbcs_click'
      });
      // this.visible_modal = true;
      this.$confirm('可以在【配置目标城市】页面详细了解!', '目标城市', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this9.$router.push({
          path: '/setting/setting_city'
        });
        _this9.filtrate.setTracking({
          en: 'zxmk_yx_gzzdsxtjmbcsck_click'
        });
      }).catch(function () {
        console.log('cancel');
      });
    },
    // 查询目标城市配置
    getTargetCity: function getTargetCity() {
      var _this10 = this;
      _attention.default.getliveUserTargetCity().then(function (res) {
        console.log('目标城市配置', res);
        if (res.code == 200) {
          _this10.show_setting = !!res.data.isOpened;
        }
      });
    },
    // 授权检查
    toAuthorizationCheck: function toAuthorizationCheck() {
      var _this11 = this;
      this.isGetAuthUrl = true;
      _attention.default.getAuthorizationStatus().then(function (res) {
        if (res.code == 200) {
          if (res.data) {
            _this11.authorizationVisible = false;
          } else {
            _this11.authorizationVisible = true;
            // 获取授权链接
            _home.default.getAuthorizationUrl().then(function (res) {
              _this11.isGetAuthUrl = false;
              if (res.code === 200) {
                if (!res.data.enterpriseAccount) {
                  _this11.$message.error('您的抖音号不是企业号，无法使用此功能');
                } else if (!res.data.authUrl) {
                  _this11.$message.error('您的账号未选中业务主号，请在风火轮APP中设置');
                } else {
                  window.open(res.data.authUrl);
                }
              }
            }).catch(function () {
              return _this11.isGetAuthUrl = false;
            });
          }
        } else {
          _this11.$message.error(res.message);
        }
      }).catch(function () {
        return _this11.isGetAuthUrl = false;
      });
    },
    visibleHange: function visibleHange(type) {
      switch (type) {
        case 'set':
          console.log('进入配置特别关注的城市');
          this.$router.push({
            path: '/setting/setting_city'
          });
          break;
        case 'authorization':
          console.log('去授权');
          // this.getAuthorizationStatus(1);
          this.toAuthorizationCheck();
          break;
        case 'login':
          // 1.确认媒体号信息
          this.loginFirstConfirm();
          this.getAuthorizationStatus(0);
          break;
      }
    },
    goRetentionData: function goRetentionData() {
      console.log('/retention_data');
      this.$router.push({
        path: '/retention_data',
        query: {
          startDay: this.startDay,
          endDay: this.endDay,
          num: this.num
        }
      });
    },
    getInitiativeMarketingAnnouncementInfo: function getInitiativeMarketingAnnouncementInfo() {
      var _this12 = this;
      _attention.default.getInitiativeMarketingAnnouncementInfo({
        clientType: 1
      }).then(function (res) {
        if (res.code == 200) {
          _this12.infoData = res.data || {};
        } else {
          _this12.$message.error(res.message);
        }
      });
    },
    changeTableList: function changeTableList() {
      this.attentionType = 'getLeaveDetail';
      // this.$refs.fptable.changeTable(this.attentionType)
      this.changeList();
    }
  }
  // async beforeRouteLeave(to, from, next) {
  //   if (to.name === "login") {
  //     next();
  //     return;
  //   }
  //   _httpC.getAuthorizationStatus().then((res) => {
  //     if (res.code == 200) {
  //       if (res.data) {
  //         this.authorizationVisible = false;
  //         next();
  //       } else {
  //         this.authorizationVisible = true;
  //         this.$message.error("抖音号未授权，请先完成授权");
  //       }
  //     } else {
  //       next({
  //         path: "/index",
  //         replace: true,
  //       });
  //     }
  //   });
  // },
};
exports.default = _default;