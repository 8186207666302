"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _textareaContent = _interopRequireDefault(require("./textareaContent"));
var _function_manage = _interopRequireDefault(require("@/api/function_manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    textareaContent: _textareaContent.default
  },
  data: function data() {
    return {
      dataList: [],
      loading: false
    };
  },
  created: function created() {
    this.getRoutineDetail();
  },
  methods: {
    //获取列表
    getRoutineDetail: function getRoutineDetail() {
      var _this = this;
      this.loading = true;
      _function_manage.default.getRoutineDetail({
        basics: 1
      }).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.dataList = res.data || [];
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    //编辑关键词
    edit: function edit(item) {
      var _this2 = this;
      this.$set(item, 'isEdit', true);
      this.$set(item, 'value', item.detailsName);
      this.$nextTick(function () {
        _this2.$refs["keyword_".concat(item.strategyId)][0].focus();
      });
    },
    //取消编辑
    close: function close(item) {
      item.isEdit = false;
      item.detailsName = item.value;
    },
    //保存关键词
    save: function save(item) {
      var _this3 = this;
      if (item.detailsName) {
        this.$set(item, 'isSubmit', true);
        var val = item.detailsName.replace(/，/g, ',');
        _function_manage.default.updateData({
          group_id: item.strategyId,
          group_name: item.strategyName,
          key_word_details_param: [{
            details_id: item.detailsId,
            details_name: val,
            operation_type: 2,
            match_type: 2
          }]
        }).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success('操作成功');
            _this3.getRoutineDetail();
          } else {
            _this3.$message.error(res.message);
          }
        });
      } else {
        this.$message.error('请输入关键词');
      }
    },
    //删除话术
    onDelete: function onDelete(res, item) {
      this.submitData(res[0], item, 3);
    },
    //提交话术
    onSubmit: function onSubmit(res, item) {
      this.submitData(res[0], item, res[0].isAdd ? 1 : 2);
      res[0].isAdd ? this.$refs["textareaContent_".concat(item.strategyId)][0].scrollLeft() : '';
    },
    //提交操作数据
    submitData: function submitData(_data, item, operationType) {
      var _this4 = this;
      var params = {
        functionType: item.functionType,
        data: {
          isFinishCheck: true,
          strategyId: item.strategyId,
          strategyType: 2,
          strategyTypeId: item.strategyTypeId,
          strategyTypeName: item.strategyTypeName,
          wordResponseList: [{
            message: _data.replyMsg,
            operationType: operationType,
            responseId: _data.isAdd ? undefined : _data.id
          }]
        }
      };
      _function_manage.default.updateRoutineData(params).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success('操作成功');
          _this4.getRoutineDetail();
        } else {
          _this4.$message.error(res.message);
        }
      });
    }
  }
};
exports.default = _default;