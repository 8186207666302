"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var transformTypeMap = {
  1: '手动转人工回复',
  2: '手动转自动回复',
  3: '系统转人工回复',
  4: '系统转自动回复'
};
var _default = {
  data: function data() {
    return {
      transformTypeMap: transformTypeMap,
      avatar: require('@/assets/dev/defult-avatar.png'),
      robotLogo: require('@/assets/live_images/robot-logo.png')
    };
  },
  props: {
    position: {
      type: String,
      default: 'left'
    },
    item: {
      type: Object,
      default: {}
    },
    author: {
      type: Object,
      default: {}
    }
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    getSimpleName: function getSimpleName(name) {
      if (name) {
        return name.slice(0, name.indexOf('-'));
      } else {
        return '--';
      }
    },
    getNewTime: function getNewTime(time) {
      if (time) {
        return time.slice(0, 16);
      } else {
        return '--';
      }
    },
    disposeUse: function disposeUse(type, name) {
      var str = '';
      switch (type) {
        case 1:
          str = '未知';
          break;
        case 2:
          str = '皮皮拉客';
          break;
        case 3:
          str = name;
          break;
        case 4:
          str = '抖音APP';
          break;
        default:
          break;
      }
      return str;
    },
    handlePreview: function handlePreview(url) {
      this.$viewerApi({
        options: {
          toolbar: false,
          navbar: false,
          title: false
        },
        images: [url]
      });
    }
  }
};
exports.default = _default;