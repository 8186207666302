var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_box" },
    [
      _c(
        "a-card",
        [
          _vm.infoData.forbiddenOperation
            ? _c("alertMarning", {
                attrs: { description: _vm.infoData.announcementInfo },
              })
            : _vm._e(),
          _c(
            "a-tabs",
            [_c("a-tab-pane", { key: 1, attrs: { tab: "高潜攀谈统计" } })],
            1
          ),
          _c(
            "header",
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  on: { change: _vm.changeDay },
                  model: {
                    value: _vm.timeBtn,
                    callback: function ($$v) {
                      _vm.timeBtn = $$v
                    },
                    expression: "timeBtn",
                  },
                },
                _vm._l(_vm.btn, function (item) {
                  return _c(
                    "a-radio-button",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
              _c("a-range-picker", {
                staticStyle: { width: "400px", margin: "0 12px" },
                attrs: {
                  placeholder: ["开始时间", "结束时间"],
                  format: "YYYY-MM-DD",
                  inputReadOnly: "",
                  "disabled-date": _vm.disabledDate,
                  allowClear: false,
                },
                on: { change: _vm.onChangeDate },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
              _c("span", { staticClass: "color1" }, [
                _vm._v("仅支持查询近30天"),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("article", [
              _c("div", { staticClass: "tips" }, [
                _vm._v("高潜攀谈互动用户的转化情况"),
              ]),
              _c("div", { staticClass: "chart-box" }, [
                _c(
                  "div",
                  [
                    _c("live-analys", {
                      attrs: {
                        "chart-data": _vm.chartData,
                        fansCount: _vm.fansCount,
                      },
                    }),
                  ],
                  1
                ),
                _vm.show_setting
                  ? _c(
                      "div",
                      { staticClass: "chart-pie" },
                      [
                        _c("live-analys-pie", {
                          attrs: { "chart-data": _vm.pieData },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "color1 right_position",
                            on: { click: _vm.settingCity },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question add_question",
                            }),
                            _vm._v("配置目标城市"),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("aside", [
              _c("div", { staticClass: "tips" }, [_vm._v("提升转化")]),
              _c("div", { staticClass: "right-item" }, [
                _c("div", { staticClass: "left-box" }, [
                  _c("p", [_vm._v("增加互动匹配数")]),
                  _c("div", { staticClass: "color1" }, [
                    _vm._v(
                      "开启「抖音APP-开直播-设置-允许观众查看他人资料」功能"
                    ),
                  ]),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openImg(2)
                        },
                      },
                    },
                    [_vm._v("看教程")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "right-box" },
                  [
                    _c(
                      "p",
                      {
                        class:
                          _vm.incrIntercat.open_fans_info == 1
                            ? "color3"
                            : "color2",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.incrIntercat.open_fans_info == 1
                                ? "当前已开启"
                                : "当前未开启"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.btn_loading && _vm.active_btn == 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updataStatus(1)
                          },
                        },
                      },
                      [_vm._v("更新状态 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "right-item" }, [
                _c("div", { staticClass: "left-box" }, [
                  _c("p", [_vm._v("配置策略回复")]),
                  _c("div", { staticClass: "color1" }, [
                    _vm._v(
                      "设置回复规则，针对价值潜客的直播互动数据，实时自动回复私信"
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.goRouter },
                      },
                      [_vm._v("去配置")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "nav",
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      on: { change: _vm.changeList },
                      model: {
                        value: _vm.attentionType,
                        callback: function ($$v) {
                          _vm.attentionType = $$v
                        },
                        expression: "attentionType",
                      },
                    },
                    [
                      _c(
                        "a-radio-button",
                        { attrs: { value: "getLiveEffect" } },
                        [_vm._v(" 转化统计")]
                      ),
                      _c(
                        "a-radio-button",
                        { attrs: { value: "getLiveProperty" } },
                        [_vm._v(" 留资统计")]
                      ),
                      _c(
                        "a-radio-button",
                        { attrs: { value: "getLeaveDetail" } },
                        [
                          _c(
                            "a-badge",
                            { attrs: { count: "hot", offset: [20, -5] } },
                            [_vm._v("留资明细 ")]
                          ),
                        ],
                        1
                      ),
                      _vm.attentionType == "getLeaveDetail" &&
                      _vm.listData.length
                        ? _c(
                            "span",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _vm._v("最多展示最新10条数据， "),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "-20px" },
                                  attrs: { type: "link" },
                                  on: { click: _vm.goRetentionData },
                                },
                                [_vm._v("点击查看更多 > ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.attentionType == "getLeaveDetail"
                        ? _c(
                            "span",
                            { staticClass: "switch_box" },
                            [
                              _c("a-switch", {
                                on: { change: _vm.showPhoneType },
                                model: {
                                  value: _vm.showPhone,
                                  callback: function ($$v) {
                                    _vm.showPhone = $$v
                                  },
                                  expression: "showPhone",
                                },
                              }),
                              _vm._v(" 展示联系方式 "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-tooltip",
                        { attrs: { placement: "leftTop" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("p", [
                              _vm._v(
                                "价值潜客人数：匹配到与主播有直播互动的用户数"
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "命中策略人数：直播互动记录命中策略的价值潜客数"
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "策略-主动私信人数：命中策略且由系统自动回复私信的价值潜客数"
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "策略有效回复人数：策略-主动私信后的7天内，有私信回复过主播的价值潜客数"
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "策略有效留资人数：策略-主动私信后的7天内，有私信留资的价值潜客数"
                              ),
                            ]),
                          ]),
                          _c(
                            "span",
                            { staticClass: "color1 finger" },
                            [
                              _c("a-icon", {
                                staticStyle: { "margin-right": "4px" },
                                attrs: { type: "question-circle" },
                              }),
                              _vm._v("数据说明"),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Fptable", {
                ref: "fptable",
                attrs: {
                  payload: _vm.time,
                  "list-data": _vm.listData,
                  showPhone: _vm.showPhone,
                  paginationLeave: _vm.pagination,
                  tableLoading: _vm.tableLoading,
                },
                on: {
                  changePagination: _vm.changePagination,
                  changeTable: _vm.changeTableList,
                },
              }),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { visible: _vm.previewVisible, footer: null },
              on: { cancel: _vm.handleCancel },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { alt: "example", src: _vm.previewImage },
              }),
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: {
                visible: _vm.loginVisible,
                title: "温馨提示",
                closable: false,
                maskClosable: false,
              },
            },
            [
              _c("div", { staticClass: "modal_content" }, [
                _c(
                  "p",
                  {
                    staticStyle: { "font-weight": "bold", "font-size": "16px" },
                  },
                  [_vm._v("当前客情主营媒体号如下：")]
                ),
                _c("div", { staticClass: "info_data" }, [
                  _c("div", { staticClass: "avatar" }, [
                    _c("span", { staticClass: "img" }, [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "border-radius": "50%",
                        },
                        attrs: {
                          src: _vm.authorizationStatusData.authorAvatar,
                          alt: "主播头像",
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dy_icon" }),
                  ]),
                  _c("div", { staticClass: "info-right" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.authorizationStatusData.authorNickname || "-"
                        )
                      ),
                    ]),
                    _c("p", { staticClass: "num" }, [
                      _vm._v(
                        _vm._s(_vm.authorizationStatusData.authorId || "-")
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    staticStyle: {
                      color: "#8b8e92",
                      padding: "10px 0",
                      "font-size": "13px",
                    },
                  },
                  [
                    _c("a-icon", {
                      attrs: { type: "exclamation-circle", theme: "filled" },
                    }),
                    _vm._v(" 更换媒体号请联系阿凡提工作人员 "),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { width: "50%" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.visibleHange("login")
                          },
                        },
                      },
                      [_vm._v("我知道了")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "a-modal",
            {
              attrs: {
                visible: _vm.authorizationVisible,
                title: "温馨提示",
                closable: false,
                maskClosable: false,
              },
            },
            [
              _c("p", [
                _vm._v("风火轮将对您门店的抖音号私信、视频评论等信息进行管理"),
              ]),
              _c("p", [_vm._v("请先完成抖音号的授权")]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { width: "50%" },
                        attrs: { type: "primary", loading: _vm.isGetAuthUrl },
                        on: {
                          click: function ($event) {
                            return _vm.visibleHange("authorization")
                          },
                        },
                      },
                      [_vm._v(" 去授权 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "a-modal",
            {
              attrs: {
                visible: _vm.setVisible,
                title: "温馨提示",
                closable: false,
                maskClosable: false,
              },
            },
            [
              _c("p", [_vm._v("配置特别关注的城市，精准识别同城用户")]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { width: "50%" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.visibleHange("set")
                          },
                        },
                      },
                      [_vm._v("去设置")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }