var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container" },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("svg-icon", {
                    staticStyle: {
                      "margin-right": "6px",
                      cursor: "pointer",
                      "font-size": "24px",
                    },
                    attrs: { "icon-class": "aftlogo" },
                  }),
                  _c(
                    "span",
                    [
                      _vm._v(_vm._s(_vm.userInformation.account)),
                      _vm.userInformation.nickname
                        ? [
                            _vm._v(
                              "（" + _vm._s(_vm.userInformation.nickname) + "）"
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dy-box",
                      staticStyle: { padding: "0 20px" },
                    },
                    [
                      _c("p", [_vm._v("风火轮账号")]),
                      _c("p", [
                        _vm._v(
                          "账号名：" + _vm._s(_vm.userInformation.account)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "使用人：" + _vm._s(_vm.userInformation.userName)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dy-box",
                      staticStyle: { padding: "0px 20px" },
                    },
                    [
                      _c(
                        "p",
                        [
                          _vm._v(" 客情主营媒体号 "),
                          _c("a-icon", {
                            staticStyle: {
                              "margin-left": "6px",
                              cursor: "pointer",
                            },
                            attrs: { type: "question-circle" },
                            on: { click: _vm.openTips },
                          }),
                        ],
                        1
                      ),
                      _vm.userInformation.nickname
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userInformation.type == 1
                                    ? "抖音："
                                    : _vm.userInformation.type == 2
                                    ? "快手："
                                    : "懂车帝："
                                ) +
                                _vm._s(_vm.userInformation.nickname) +
                                "（" +
                                _vm._s(_vm.userInformation.code) +
                                "） "
                            ),
                          ])
                        : _c("p", [_vm._v("未设置")]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "", id: "test-logout" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { display: "block" } },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "leave" } }),
                          _vm._v("退出登录"),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }