var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    _vm._g(_vm._b({}, "a-drawer", _vm.$attrs, false), _vm.$listeners),
    [
      _vm._t("title", null, { slot: "title" }),
      _c("div", { staticClass: "drawer-content" }, [_vm._t("default")], 2),
      _vm._t("footer", [
        _vm._t("fixed-footer"),
        _c(
          "div",
          { staticClass: "drawer-footer" },
          [
            _c(
              "a-button",
              {
                staticStyle: { "margin-right": "8px" },
                on: {
                  click: function () {
                    return _vm.$listeners.close
                      ? _vm.$listeners.close()
                      : _vm.emptyFun()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]
            ),
            _vm.$listeners.submit
              ? _c(
                  "a-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.submit },
                  },
                  [_vm._v(_vm._s(_vm.submitText))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }