"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkToken = checkToken;
exports.default = void 0;
exports.feishuLogin = feishuLogin;
exports.getAccountMessage = getAccountMessage;
exports.getDyLimit = getDyLimit;
exports.getInfo = getInfo;
exports.getManufactorList = getManufactorList;
exports.getUserInfo = getUserInfo;
exports.login = login;
exports.logout = logout;
exports.useAuthCodeLogin = useAuthCodeLogin;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _lodash = _interopRequireDefault(require("lodash"));
// import request from '@/utils/request'

var request = new _NetworldRequest.default().getAxios();
var shieldReq = new _NetworldRequest.default({
  needMessage: false
}).getAxios();
var shielNrWithoutToken = new _NetworldRequest.default({
  needMessage: false,
  needTocken: false
}).getAxios();
function login(data) {
  return shielNrWithoutToken({
    url: '/cas/oauth/token',
    method: 'post',
    data: data
  }).then(function (res) {
    if (res.data.errcode == 4002 || res.data.errcode == 4001 || res.data.errcode == 2001) {
      return res;
    } else {
      // getUserInfo(`${res.data.data.token_head}${res.data.data.token}`).then(userRes => {
      //   console.log('res', res)
      //   console.log('userRes', userRes)
      //   return res
      // })
      return res;
      // let id = res.data.manufactor_id.split(',')[0]
      // cookie.set('avatar', `https://jujinta.oss-cn-hangzhou.aliyuncs.com/manufactor/images/${id}.png`, {domain: process.env.VUE_APP_COOKIE_DOMAIN})
    }
  });
}

function useAuthCodeLogin(data) {
  return shielNrWithoutToken({
    baseURL: '/fhl-app',
    url: "/cas/token",
    method: 'post',
    data: data
  }).then(function (res) {
    if (res.data.errcode == 4002 || res.data.errcode == 4001 || res.data.errcode == 2001) {
      return res;
    } else {
      // getUserInfo(`${res.data.data.token_head}${res.data.data.token}`).then(userRes => {
      //   console.log('res', res)
      //   console.log('userRes', userRes)
      //   return res
      // })
      return res;
      // let id = res.data.manufactor_id.split(',')[0]
      // cookie.set('avatar', `https://jujinta.oss-cn-hangzhou.aliyuncs.com/manufactor/images/${id}.png`, {domain: process.env.VUE_APP_COOKIE_DOMAIN})
    }
  });
}

function getInfo() {
  return request({
    url: '/cas/oauth/listMenu',
    method: 'get'
  }).then(function (res) {
    // let id = _.get(res, "data.data.current_manufactor_id", null)
    // if (id) {
    //   id = id.split(',')[0]
    //   cookie.set('avatar', `https://jujinta.oss-cn-hangzhou.aliyuncs.com/manufactor/images/${id}.png`, {domain: process.env.VUE_APP_COOKIE_DOMAIN})
    // } else {
    //   cookie.set('avatar', '//afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/dev/avatar.jpeg', {domain: process.env.VUE_APP_COOKIE_DOMAIN})
    // }

    // cookie.set('name', _.get(res, "data.data.name", ''), {domain: process.env.VUE_APP_COOKIE_DOMAIN})
    return res;
  });
}
function getUserInfo(token) {
  return request({
    url: '/cas/oauth/user',
    headers: {
      'Authorization': token
    },
    method: 'get'
  });
}
function getManufactorList(token) {
  return request({
    url: '/api/token/manufactor',
    method: 'get',
    params: {
      token: token
    }
  });
}
function checkToken(manufactor_id) {
  return request({
    url: '/api/token/manufactor-token',
    method: 'put',
    data: manufactor_id
  });
}
function logout() {
  return request({
    url: '/cas/oauth/logout',
    method: 'get'
  });
}

// 查询账号信息
function getAccountMessage() {
  return request({
    url: '/mkms/marketingStatistic/accountInfo',
    method: 'get'
  });
}

// 抖音私信额度
function getDyLimit() {
  return request({
    url: "/mkms/marketingStatistic/dyLimit",
    method: 'get'
  });
}
var _default = {
  // 获取手机验证码
  getPhoneCode: function getPhoneCode(params) {
    return shielNrWithoutToken({
      baseURL: '/fhl-app',
      url: "/ums/author/phoneCode",
      method: 'get',
      params: params
    });
  },
  //获取飞书授权配置转发
  getLarkAppId: function getLarkAppId(id) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/feishu/oauth/config/".concat(id),
      method: 'get'
    });
  }
};
exports.default = _default;
function feishuLogin(data) {
  return request({
    baseURL: process.env.VUE_APP_LIVE_CRM_API,
    url: "/cas/oauth/v1/token",
    method: 'post',
    data: data
  });
}