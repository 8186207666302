var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c("div", { staticClass: "tips-box" }, [
          _c("div", { staticClass: "title-header" }, [_vm._v("人工回复时段")]),
          _c("p", [_vm._v("人工时段内，只有三种情况皮皮拉客会自动回复：")]),
          _c("p", [_vm._v("1、用户进入会话，会发一条欢迎语")]),
          _c("p", [_vm._v("2、用户一定时间未回复，会触发一条召回话术")]),
          _c("p", [_vm._v("3、用户留资后，会发一条类似“收到”的话术")]),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            [
              _c("drag-week-time", {
                staticStyle: { width: "800px" },
                attrs: { colspan: 2 },
                model: {
                  value: _vm.timeStr,
                  callback: function ($$v) {
                    _vm.timeStr = $$v
                  },
                  expression: "timeStr",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "tips-box" }, [
          _c("div", { staticClass: "title-header" }, [
            _vm._v("自动转人工设置"),
          ]),
          _c(
            "p",
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.auto,
                    callback: function ($$v) {
                      _vm.auto = $$v
                    },
                    expression: "auto",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: true } }, [_vm._v(" 启用 ")]),
                  _c("a-radio", { attrs: { value: false } }, [
                    _vm._v(" 停用 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(
              "1、使用前请务必先关闭其他自动回复私信工具，否则其他工具回复后，系统也会自动转人工"
            ),
          ]),
          _c("p", [
            _vm._v(
              " 2、当系统识别到人工在回复某用户私信时，将停止自动回复，距用户最新一条消息10分钟内没有人工回复，或距离主播最新一条消息超过20分钟后，系统将切换为自动回复，用户再次私信将由皮皮拉客承接 "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "content", staticStyle: { "margin-bottom": "24px" } },
          [
            _c(
              "a-button",
              {
                staticClass: "save-btn",
                attrs: { type: "primary", loading: _vm.saving },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }