var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c("img", {
        staticClass: "cover",
        attrs: { src: _vm.imgSrc, alt: "", srcset: "" },
      }),
      _vm.type !== _vm.TipsType.CHECKING
        ? _c("span", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.tipsText))])
        : _c("span", { staticClass: "tips" }, [
            _vm._v("添加的抖音号"),
            _c("span", { staticClass: "media-name" }, [
              _vm._v(_vm._s(_vm.checkMediaList.join("、"))),
            ]),
            _vm._v("正在审核中，请耐心等待.."),
          ]),
      _c(
        "a-button",
        {
          attrs: {
            type: "primary",
            loading: _vm.isCheckMediaLoading || _vm.isGetAuthUrlLoading,
          },
          on: { click: _vm.handleButtonClick },
        },
        [_vm._v(_vm._s(_vm.tipsButtonText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }