var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _vm.dataList && _vm.dataList.length > 0
        ? _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "div",
              { key: item.strategyId, staticClass: "box" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.strategyName)),
                ]),
                _c("div", { staticClass: "txt" }, [
                  _vm._v(_vm._s(item.strategyDescription)),
                ]),
                item.detailsId
                  ? _c("div", { staticClass: "keyword" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("关键词:")]),
                      _c(
                        "div",
                        { staticClass: "input-box" },
                        [
                          !item.isEdit
                            ? _c(
                                "div",
                                { staticStyle: { padding: "4px 11px" } },
                                [_vm._v(_vm._s(item.detailsName) + " ")]
                              )
                            : _c("a-textarea", {
                                ref: "keyword_" + item.strategyId,
                                refInFor: true,
                                staticClass: "input",
                                attrs: {
                                  placeholder: "请输入关键词",
                                  "auto-size": { minRows: 2, maxRows: 4 },
                                  maxLength: 200,
                                  disabled: !item.isEdit,
                                },
                                model: {
                                  value: item.detailsName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "detailsName", $$v)
                                  },
                                  expression: "item.detailsName",
                                },
                              }),
                          _c(
                            "div",
                            { staticClass: "bottom" },
                            [
                              !item.isEdit
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn edit",
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "edit" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "btn",
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.close(item)
                                          },
                                        },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          disabled: item.isSubmit,
                                          loading: item.isSubmit,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.save(item)
                                          },
                                        },
                                      },
                                      [_vm._v("保存")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("textareaContent", {
                  ref: "textareaContent_" + item.strategyId,
                  refInFor: true,
                  attrs: { "data-list": item.replyMsgVos || [] },
                  on: {
                    delete: function ($event) {
                      return _vm.onDelete(arguments, item)
                    },
                    submit: function ($event) {
                      return _vm.onSubmit(arguments, item)
                    },
                  },
                }),
              ],
              1
            )
          })
        : _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }