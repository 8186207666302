var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-table",
        {
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.listData,
            bordered: "",
            pagination: _vm.paginationLeave,
            rowKey: function (record, index) {
              return index
            },
            loading: _vm.tableLoading,
            scroll: { x: 1200 },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "user_nicknames",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    _vm._l(_vm.formatList(text), function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  )
                },
              },
              {
                key: "intention_series",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    _vm._l(_vm.formatList(text), function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  )
                },
              },
              {
                key: "contact",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text.contact_type === 2
                        ? _c("span", [_vm._v(" " + _vm._s(text.contact) + " ")])
                        : [
                            !_vm.showPhone
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.phoneNuberConvert(text.contact))
                                  ),
                                ])
                              : _c("span", [_vm._v(_vm._s(text.contact))]),
                          ],
                    ],
                    2
                  )
                },
              },
              {
                key: "leave_type",
                fn: function (text) {
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.leaveTypeMap[text]) + " "),
                  ])
                },
              },
              {
                key: "disposePercent",
                fn: function (text, record) {
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(text)),
                    _c("span", [
                      _vm._v(
                        "/" +
                          _vm._s(_vm.disposePercent(text, record.interactCount))
                      ),
                    ]),
                  ])
                },
              },
              {
                key: "disposeLeave",
                fn: function (text, record) {
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(text)),
                    _c("span", [
                      _vm._v(
                        "/" +
                          _vm._s(
                            _vm.disposePercent(
                              text,
                              record.sevenDayLeaveInfoCount
                            )
                          )
                      ),
                    ]),
                  ])
                },
              },
              {
                key: "sevenDayCount",
                fn: function (text) {
                  return _c("div", {}, [_vm._v(" " + _vm._s(text || "") + " ")])
                },
              },
              {
                key: "userAvatar",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text
                        ? _c("div", { staticClass: "avatar_img" }, [
                            _c("img", { attrs: { src: text } }),
                          ])
                        : _c("svg-icon", { attrs: { "icon-class": "user" } }),
                    ],
                    1
                  )
                },
              },
              {
                key: "phoneNumList",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    _vm._l(text, function (item) {
                      return _c("div", { key: item }, [
                        !_vm.showPhone
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.phoneNuberConvert(item))),
                            ])
                          : _c("span", [_vm._v(_vm._s(item))]),
                      ])
                    }),
                    0
                  )
                },
              },
              {
                key: "phoneNum",
                fn: function (text) {
                  return _c("div", {}, [
                    !_vm.showPhone
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.phoneNuberConvert(text))),
                        ])
                      : _c("span", [_vm._v(_vm._s(text))]),
                  ])
                },
              },
              {
                key: "wait_contact_time",
                fn: function (text) {
                  return _c("div", {}, [
                    text.follow_up_state != 3
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDuration(text.wait_contact_time)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "remarks",
                fn: function (text) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("span", { staticClass: "remarks_text" }, [
                          _vm._v(" " + _vm._s(text.remarks) + " "),
                        ]),
                        _c("a-icon", {
                          staticStyle: {
                            width: "25px",
                            height: "25px",
                            "font-size": "24px",
                            color: "#40a9ff",
                            cursor: "pointer",
                          },
                          attrs: { type: "form" },
                          on: {
                            click: function ($event) {
                              return _vm.editRemarks(text)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                },
              },
              {
                key: "followUpState",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "90px" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.changeEdit(text)
                            },
                          },
                          model: {
                            value: text.follow_up_state,
                            callback: function ($$v) {
                              _vm.$set(text, "follow_up_state", $$v)
                            },
                            expression: "text.follow_up_state",
                          },
                        },
                        _vm._l(_vm.followUpStateList, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "interactionList",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-tooltip",
                        {
                          attrs: { overlayStyle: { "min-width": "360px" } },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    text.key_word_value_interaction_list &&
                                    text.key_word_value_interaction_list.length
                                      ? _c("p", [
                                          _vm._v(" 关键词： "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                text.key_word_value_interaction_list.join(
                                                  "，"
                                                )
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    text.chat_value_interaction_list &&
                                    text.chat_value_interaction_list.length
                                      ? _c("p", [
                                          _vm._v(" 私信： "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                text.chat_value_interaction_list.join(
                                                  "，"
                                                )
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    text.live_value_interaction_list &&
                                    text.live_value_interaction_list.length
                                      ? _c("p", [
                                          _vm._v(" 评论： "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                text.live_value_interaction_list.join(
                                                  "，"
                                                )
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          text.key_word_value_interaction_list &&
                          text.key_word_value_interaction_list.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _vm._v(" 关键词： "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "keyWordValueInteractionList_text",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            text.key_word_value_interaction_list.join(
                                              "，"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          text.chat_value_interaction_list &&
                          text.chat_value_interaction_list.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _vm._v(" 私信： "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "keyWordValueInteractionList_text",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            text.chat_value_interaction_list.join(
                                              "，"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          text.live_value_interaction_list &&
                          text.live_value_interaction_list.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _vm._v(" 评论： "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "keyWordValueInteractionList_text",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            text.live_value_interaction_list.join(
                                              "，"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "hitStrategyList",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    _vm._l(text.hit_strategy_list, function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  )
                },
              },
              {
                key: "hit_strategy_list",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    _vm._l(text.hit_strategy_list, function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  )
                },
              },
              {
                key: "action",
                fn: function (text) {
                  return text.user_list && text.user_list.length > 0
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openDrawer(text)
                            },
                          },
                        },
                        [_vm._v(" 营销回溯 ")]
                      )
                    : _vm._e()
                },
              },
              {
                key: "code",
                fn: function (text) {
                  return _c("div", {}, [_vm._v(_vm._s(text ? text : "--"))])
                },
              },
              {
                key: "location_city",
                fn: function (text) {
                  return _c("div", {}, [_vm._v(_vm._s(text ? text : "未知"))])
                },
              },
              {
                key: "mention_city",
                fn: function (text) {
                  return _c("div", {}, [_vm._v(_vm._s(text ? text : "未知"))])
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "div",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { slot: "targetCityTitle" },
              slot: "targetCityTitle",
            },
            [
              _vm._v(" 目标城市留资人数/率 "),
              _c("a-icon", {
                attrs: { type: "question-circle" },
                on: { click: _vm.settingCity },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            placement: "right",
            visible: _vm.visible,
            width: 640,
            maskClosable: false,
          },
          on: { close: _vm.onClose },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticClass: "drawer-header" },
              [
                _vm._v(" 营销回溯 "),
                _c(
                  "a-button",
                  {
                    staticClass: "to-message",
                    attrs: { type: "default" },
                    on: { click: _vm.gotoChat },
                  },
                  [_vm._v(" 私信Ta")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "a-tabs",
            {
              on: { change: _vm.changeUser },
              model: {
                value: _vm.currentUserIndex,
                callback: function ($$v) {
                  _vm.currentUserIndex = $$v
                },
                expression: "currentUserIndex",
              },
            },
            _vm._l(_vm.userList, function (item, index) {
              return _c("a-tab-pane", {
                key: index,
                attrs: { tab: item.user_nickname },
              })
            }),
            1
          ),
          _c("a-spin", { attrs: { spinning: _vm.historyListLoading } }, [
            _c(
              "div",
              { ref: "chatBox", staticClass: "chat-box" },
              [
                _vm.history_chat_list.length > 0
                  ? _vm._l(_vm.history_chat_list, function (item) {
                      return _c("talk-record", {
                        key: item.id,
                        attrs: {
                          position: item.is_author_send == 1 ? "right" : "left",
                          item: item,
                          author: _vm.currentAuthorData,
                        },
                      })
                    })
                  : _c("a-empty", { staticStyle: { "margin-top": "40px" } }),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "备注" },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
          model: {
            value: _vm.remarks_visible,
            callback: function ($$v) {
              _vm.remarks_visible = $$v
            },
            expression: "remarks_visible",
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "remarks" } },
                [
                  _c("el-input", {
                    staticClass: "resizeNone",
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入备注",
                      maxlength: "100",
                      rows: 3,
                      autosize: false,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "remarks",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }