var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("回复内容:")]),
      _c(
        "div",
        { ref: "listWrap", staticClass: "list-wrap" },
        [
          _vm.list && _vm.list.length > 0
            ? _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "item" },
                  [
                    _c("a-textarea", {
                      ref: "textarea_" + item.id,
                      refInFor: true,
                      staticClass: "txt",
                      attrs: {
                        placeholder: "请输入回复内容",
                        maxLength: 300,
                        disabled: !item.isEdit,
                      },
                      model: {
                        value: item.replyMsg,
                        callback: function ($$v) {
                          _vm.$set(item, "replyMsg", $$v)
                        },
                        expression: "item.replyMsg",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "bottom" },
                      [
                        !item.isEdit
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "btn edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editTactics(item)
                                    },
                                  },
                                },
                                [_c("a-icon", { attrs: { type: "edit" } })],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "btn delete" },
                                [
                                  !item.isSubmit
                                    ? _c("a-icon", {
                                        attrs: { type: "delete" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteTactics(item)
                                          },
                                        },
                                      })
                                    : _c("a-icon", {
                                        attrs: { type: "loading" },
                                      }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "a-button",
                                {
                                  staticClass: "btn",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.close(item, index)
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    disabled: item.isSubmit,
                                    loading: item.isSubmit,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submit(item)
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                )
              })
            : [_vm._v(" 暂无话术 ")],
          !_vm.isAdd && _vm.list.length < 10
            ? _c(
                "div",
                { staticClass: "add", on: { click: _vm.addTactics } },
                [_c("a-icon", { attrs: { type: "plus-circle" } })],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("a-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }